import React, { useEffect } from 'react';

function lowRateACHRedirectSetPassword({ location }) {
  useEffect(() => {
    const target = '/credit-cards/low-rate/addcard/set-password/';

    function fetchRedirectUrl() {
        const searchParams = window.location.search;
        return searchParams ? target + searchParams : target;
    }

    if (window?.location) {
      window.location.replace(fetchRedirectUrl());
    }
  }, []);

  return <></>;
}

export default lowRateACHRedirectSetPassword;
